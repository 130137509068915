import './global.css'

import '../public/styles/assets/css/font-awesome.min.css'
import '../public/css/bootstrap.min.css'

import '../public/css/skydash.css'

import '../public/vendors/feather/feather.css'
import '../public/vendors/ti-icons/css/themify-icons.css'
import 'toastr/build/toastr.min.css'

import type { AppProps } from 'next/app'
import React from 'react'
import Head from 'next/head'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <React.Fragment>
      <Head>
        <title>NroNew - Bảng quản trị</title>
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  )
}

export default MyApp
